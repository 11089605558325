module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Demo Lawn Cadillac","short_name":"Demo Lawn Cadillac","start_url":"/","theme_color":"#13cce8","background_color":"#ffffff","icon":"src/images/lobster512.png","include_favicon":false,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"cd1a0f042c983b8b36e2e7ba047813cd"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://cadillac-lawn.lobstermarketing.com"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-tagmanager-timeout/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PHR5M4C","includeInDevelopment":false,"timeout":5000,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"page-change"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Home","exclude":["/dev-404-page/","/404/","/404.html"],"crumbLabelUpdates":[{"pathname":"/blog","crumbLabel":"Blog"},{"pathname":"/photo-gallery","crumbLabel":"Photo Gallery"},{"pathname":"/undefined"}]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-70},
    },{
      plugin: require('../../gatsby-theme-lawn-cadillac/gatsby-browser.js'),
      options: {"plugins":[],"projectId":"b8fc8a70-86ec-001e-a62e-49c33d5f214d","authorizationKey":"ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogImVkMTNlNzRkYTIzNTRjYzM4ZmI3ZGRjNmMxZGFkYWVlIiwNCiAgImlhdCI6ICIxNjM1MzQ3MzIyIiwNCiAgImV4cCI6ICIxOTgwOTQ3MzIyIiwNCiAgInByb2plY3RfaWQiOiAiYjhmYzhhNzA4NmVjMDAxZWE2MmU0OWMzM2Q1ZjIxNGQiLA0KICAidmVyIjogIjEuMC4wIiwNCiAgImF1ZCI6ICJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSINCn0.stqxLEuEPOakWCqNDEGFngWWLyY582c0Q7thhXKz2pE","usePreviewUrl":"false","whereWeServicePathName":"where-we-service","whereWeServiceBreadcrumb":"Where We Service","wWSActive":"0","siteUrl":"https://cadillac-lawn.lobstermarketing.com","title":"Demo Lawn Cadillac","description":"FieldRoutes","domainVerification":"meta-tag","gtmId":"GTM-PHR5M4C","compassID":"8178cfb10750a2a33c491689ef9ff80ab3043c2dea7a983121c513d31d0a3aa9","recaptchaSiteKey":"6Lc5XdohAAAAAO1_YiwUOYsG7hgGQ0g-CBT1yFbD","recaptchaSecretKey":"6Lc5XdohAAAAAHPLlsq3cLynfXPgCyuqrZ7iyv1N","photoGalleryPathName":"photo-gallery","photoGalleryBreadcrumb":"Photo Gallery","photoGalleryActive":"0"},
    }]
